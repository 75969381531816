import { isNotNull } from "@enymo/ts-nullsafe";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "react-photo-view/dist/react-photo-view.css";
import { io } from "socket.io-client";
import { version } from "../../package.json";
import { NullableTranslations, Translations } from "./resources";

// Helpers

export const EmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const now = new Date();

export function formatLikes(locale: string, value: number): string {
    return value >= 1000 ? `${(value / 1000).toLocaleString(locale, {
        maximumFractionDigits: 1
    })}K` : value.toLocaleString(locale)
}

export function formatDuration(value: number): string {
    const result = `${Math.floor((value % 3600) / 60)}:${Math.round(value % 60).toString().padStart(2, "0")}`;
    return value >= 3600 ? `${Math.floor(value / 3600)}:${result}` : result;
}

export function formatSI(value: number, units: string[]) {
    let index = 0;
    while (value >= 1000 && index < units.length - 1) {
        value /= 1000;
        index++;
    }
    return `${Math.round(value)} ${units[index]}`;
}

export function formatFilesize(value: number) {
    return formatSI(value, ["B", "KB", "MB", "GB"]);
}

export const byId = (id: any) => (item: {
    id: any
}) => item.id === id

export const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');

export function toISOStringWithTimezone<T extends Date | null | undefined>(date: T, dateOnly: boolean = false): T extends Date ? string : T {
    if (!isNotNull(date)) {
        return date as T extends Date ? string : T;
    }
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    const dateString = date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate());
    return (dateOnly ? dateString : dateString +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        diff + pad(tzOffset / 60) +
        ':' + pad(tzOffset % 60)) as T extends Date ? string : T;
};

export const socket = io(import.meta.env.VITE_SOCKET_PUBLIC_URL?.replace("{hostname}", window.location.hostname), {
    autoConnect: false,
    withCredentials: true
});

// Date formatting

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

// Version Info

console.info(`Sextermedia version ${version} - Copyright © ${now.getFullYear()} Sextermedia`);

export function mapObject<T extends {}, K>(input: T, predicate: (item: T[keyof T]) => K): {[P in keyof T]: K} {
    return Object.fromEntries(Object.entries<T[keyof T]>(input).map(([key, value]) => [key, predicate(value)])) as {[P in keyof T]: K};
}

export function sanitizeTranlations(translations: Translations): NullableTranslations {
    return mapObject(translations, translation => translation?.description && translation.title ? translation : null);
}

export const returnFalse = () => false;